@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:wght@300;400;600&display=swap");
html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Raleway", sans-serif;
}

.carousel-indicators li {
  background-color: rgb(239, 186, 129);
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23efba81' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23efba81' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-caption {
  bottom: 25%;
}

.mainColorText {
  color: rgb(239, 186, 129);
}

.courseCard {
  padding: 50px 0px !important;
}

.mainHeader {
  background-size: cover;
  height: 500px;
  margin-top: 66px;
}

.mainButton {
  background-color: rgb(239, 186, 129) !important;
  color: black !important;
  transition: background-color 0.5s ease !important;
  border: none;
}

.mainButton:hover {
  background-color: rgb(228, 203, 175) !important;
}

.banner {
  background-size: cover;
}

.bannerText {
  margin: 3rem;
  color: white;
  font-size: 2.2rem;
}

.form-control {
  color: rgba(73, 80, 87, 1);
  background-color: transparent;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid rgba(73, 80, 87, 1);
}

.form-control:focus {
  color: rgba(73, 80, 87, 1);
  background-color: transparent;
  box-shadow: none;
  border-bottom-color: rgb(239, 186, 129);
}

a:hover {
  color: rgb(199, 156, 110);
}


@media screen and (max-width: 600px) {
  .display-4 {
    font-size: 2.0rem;
  }

  .mobile {
    height: 200px !important
  }
  
}
